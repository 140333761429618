@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";

/* reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*   */

.ant-btn-primary {
  border-color: #8b2f8d;
  background-color: #8b2f8d;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #bf3888;
  background: #bf3888;
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  border-color: #8b2f8d;
  color: #8b2f8d;
}
